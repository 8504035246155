@import '../../../__styles/responsive.scss';
@import '../../../__styles/globals.scss';

$education: $purple;

.education-container {
  border-top: 0.1px solid $education;
  display: flex;
  flex-flow: column;
  margin: 10px;
  padding: 10px;

  .school-name {
    color:$education;
    font-weight: 400;
  }

    ul {
      list-style-type: square;
      li {}
    }

    .major {
      color:$lighterpink;
      font-weight: 100;
      font-size: 2em;
    }
    .minor {
      color:$pink;
      font-weight: 100;
      font-size: 1.2em;
    }
    .focus {
      color:$teal;
    }
    .curriculum-container {
      .curriculum-header {}
      ul {
        li {}
      }
    }
    .tech-container {
      .tech-header {}
        ul {
          li {}
        }
    }
    .projects-container {
      .tech-header {}
        ul {
          li {}
        }
    }

    @media only screen and (max-width: $lgDevice) {}
    @media only screen and (max-width: $mdDevice) {}
    @media only screen and (max-width: $smDevice) {}
    @media only screen and (max-width: $xsDevice) {}
}