@import '../__styles/responsive.scss';
@import '../__styles/globals.scss';

$hover-color: #fcfa85;

$dope: 'dope';

@font-face {
    font-family: 'dope';
    src: url(../assets/Dopestyle.ttf) format('truetype');
}

$bomb: 'bomb';

@font-face {
    font-family: 'bomb';
    src: url(../assets/Painterz.ttf) format('truetype');
}

.banner-container {
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
    flex-flow: row;
    color: white;
    background-color: #fff;
    font-weight: 400;
    -webkit-justify-content: center;
    align-items: flex-start;
    justify-content: center;
    margin: 0 50px;
    left: 0px;
    width: 90%;
    top: calc(100vh - 120px);
    font-family: $dope;

    .toggle-logo-container {
        display: none;
    }

    .text-container {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;

        .banner-item {
            font-size: 3em;
            font-weight: 100;
            margin: 5px 20px;
            padding: auto 20px;
        }

        .name {
            color: $pink;
        }

        .role {
            color: #000;
        }

        .underscore {
            width: 86px;
            height: 86px;
            color: #E80C7A;
            background-image: url('../assets/droopy_e.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }


    @media only screen and (max-width: $xlDevice) {
        .text-container {
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            justify-content: flex-end;

            .banner-item {
                padding: 1px;
                font-size: 2.5em;
            }
        }
    }

    @media only screen and (max-width: $lgDevice) {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        top: 8px;
        left: 0px;
        width: 100%;

        .text-container {
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            justify-content: flex-start;

            .banner-item {
                padding: 1px;
                font-size: 2.5em;
            }
        }

        .toggle-logo-container {
            height: 100px;
            width: 7%;
            display: flex;

            .navbar-toggle {
                position: absolute;
                flex-flow: row;
                top: 10px;
                right: 20px;
                cursor: pointer;
                color: rgba(255, 255, 255, 0.8);

                .fa-bars {
                    font-size: 2em;
                    color: $grayish;
                }

                .spin {
                    padding: 15px 15px 10px;
                    background-color: $pink;
                    border: 0px;
                    border-radius: 3px;
                }
            }
        }

        .text-container {
            display: flex;
            flex-flow: row;

            .underscore {
                display: none;
            }

            .banner-item {
                padding: 0;
                margin: 0;
                font-size: 1.6em;
                padding: 5px 20px;
            }
        }
    }

    @media only screen and (max-width: $mdDevice) {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin: 5px 0 0 0;
        top: 8px;
        left: 0px;
        width: 100%;
        height: 103px;
        margin-bottom: 20px;

        .toggle-logo-container {
            height: 0px;
            width: 100%;
            display: flex;

            .navbar-toggle {
                position: absolute;
                flex-flow: row;
                top: 10px;
                right: 20px;
                cursor: pointer;
                font-size: 1em;
                color: rgba(255, 255, 255, 0.8);

                .fa-bars {
                    font-size: 2em;
                }
            }
        }

        .text-container {
            display: flex;
            flex-flow: column;
            font-size: 1em;
            padding-left: 20px;
        }
    }

    @media only screen and (max-width: $smDevice) {
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        margin: 0;
        top: 5px;
        left: 0px;
        height: 83px;
        font-size: 1em;

        .toggle-logo-container {
            width: 100%;

            .navbar-toggle {
                position: absolute;
                display: flex;
                flex-flow: row;
                top: 10px;
                right: 20px;
                cursor: pointer;
                font-size: 1em;
                color: rgba(255, 255, 255, 0.8);

                .fa-bars {
                    font-size: 2em;
                }

                .spin.BurgerSpin.Burger {
                    padding: 10px 0;
                    width: 50px;
                    height: 45px;
                }
            }
        }

        .text-container {
            display: flex;
            flex-flow: column;
            padding-top: 30px;
            margin: 0 auto;

            .banner-item {
                margin: 0;
                font-size: 1.6em;
                padding: 2px;

                .role {
                    padding-left: 20px;
                }
            }
        }
    }

    @media only screen and (max-width: $xsDevice) {
        .text-container {
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 15px;
            margin: 10px auto;

            .banner-item {

                padding: 3px;
                margin: 0;
                font-size: 1.3em;
            }
        }

    }
}