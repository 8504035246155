
// Colors
$hover-color: #E0EC31;
$teal: #42D7A7;
$purple: #AA5EF4;
$pink: #E80C7A;
$lighterpink: #e64595;
$yellow:#E0EC31;
$grayish: rgb(136, 141, 170);
$darkGrayish: rgb(57, 57, 57);

$antiquewhite: #faebd7;
$transWhite: rgba(255,255,255,0.5);