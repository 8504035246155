@import '../../../__styles/responsive.scss';
@import '../../../__styles/globals.scss';

$dope: 'dope';
@font-face {
  font-family: 'dope';
  src: url(../../../assets/Dopestyle.ttf) format('truetype');
}

.skillset-container {
  border-top: 0.1px solid $teal;
  width: 48%;
  
  .text-container {
    flex-flow: column;
    margin: 10px;
    padding: 10px;
    
    i.fas.fa-syringe {
      display: flex;
      position: relative;
      color: $purple;
      opacity: 1;
      transform: rotate(0deg);
    }
    .skillset-header {
      color:$darkGrayish;
      font-weight: 400;
      font-family: $dope;
      font-size: 2em;

    }
    .skill-list-subheader {
      color: $purple;
      span {
        color:$yellow;
        
      }
      ul {
        list-style-type: square;
        color: #ffffff;
      }
    }
    .skillset-subheader {}
  }

  @media only screen and (max-width: $mdDevice) {
    width: 100%;
  }
  @media only screen and (max-width: $smDevice) {
    width: 100%;
  }
}