@import '../__styles/globals.scss';
@import '../__styles/responsive.scss';


// @keyframes go-dark {
//   0% {
    
//   }
//   20% {

//   }
//   40% {

//   }
//   60% {
//     background-image: url('./assets/plant.png');
//   }
//   80% {

//   }
//   100% {

//   }
// }

// Landing onload
@mixin landing-bubble($max-elems) {
  @for $i from 1 through $max-elems {
    &:nth-child(#{$i}) {
      animation: 2s ease-in-out 1 pulse;
    }
  }
}

// Landing onload
@mixin landing-bubble-desktop($max-elems) {
  @for $i from 1 through $max-elems {
    &:nth-child(#{$i}) {
      animation: 2s ease-in-out 1 pulse-desktop;
    }
  }
}

// Landing onload
@mixin bubble-1-desktop {
  animation: 3s ease 1 bubble-one;
}


// Landing onload
@keyframes bubble-one {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Landing onload
@keyframes pulse-desktop {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.25;
    color:$pink;
  }
  25% {
    opacity: 0.5;
    color:$pink;
  }
  50% {
    background-color: #fff;
    color:$pink;
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// Landing onload
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0;
  }
  50% {
    font-size: calc(0%);
    background-color: $pink;
    color:#fff;
    display:none;
  }
  100% {
    opacity: 1;
  }
}

// Page onload
@mixin fade-load-mixin {
  animation: fade-load 1133ms ease-in 1;
}

// PAge onload
@keyframes fade-load {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
