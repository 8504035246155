@import '../../../__styles/responsive.scss';
@import '../../../__styles/globals.scss';

@keyframes bloopbleep {
  0% {
    opacity: 0;
    transform: scale(1);
    transform: rotate(0deg);
  }
  50% {
    color: $purple;
    transform: rotate(0deg);
    transform: scale(1.7);
  }
  70% {
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
    transform: rotate(-10deg);
  }
}

$dope: 'dope';
@font-face {
  font-family: 'dope';
  src: url(../../../assets/Dopestyle.ttf) format('truetype');
}

.resume-container {
  border-top: 0.1px solid $pink;
  display: flex;
  flex-flow: column;
  width: 48%;
  font-weight: 400;
  margin: 0 20px 0 0;
  
  .resume-sub-container {}
  
  .text-container {
    display: flex;
    flex-flow: column;
    margin: 10px;
    padding: 10px;
    .resume-text {
      color: $darkGrayish;
      font-weight: 400;
      font-family: $dope;
      font-size: 2em;
    }
  }

  .image-container {
    margin: 8px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    a {
      margin: 18px;
      // i {
      //   color: $teal;
      //   transform: rotate(-10deg);
      //   transition: 500ms;
      //   z-index: 0;
      //   animation: bloopbleep 700ms ease-in-out 0s 1;
      //   &:hover {
      //     color: $purple;
      //     transform: rotate(0deg);
      //     transform: scale(1.2);
      //   }
      // }
      img {
        color: $teal;
        width: 288px;
        transform: rotate(-10deg);
        transition: 500ms;
        z-index: 0;
        animation: bloopbleep 700ms ease-in-out 0s 1;
        &:hover {
          color: $purple;
          transform: rotate(0deg);
          transform: scale(1.2);
        }
      }
    }
  }
  

  @media only screen and (max-width: $mdDevice) {
    width: 100%;
  }
  @media only screen and (max-width: $smDevice) {
    width: 100%;
  }
  @media only screen and (max-width: $xsDevice) {
    width: 100%;
  }
}