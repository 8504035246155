@import './__styles/globals.scss';

* {
  font-weight: 100;
  font-size: 20px;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;

  div, i, li, a {
    color:black;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-button {
  background-color: $darkGrayish;
}
::-webkit-scrollbar-button {
  background-color: $darkGrayish;
}

/* Track */
::-webkit-scrollbar-track {
  background: none; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $darkGrayish; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $yellow; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
