@import '../__styles/globals.scss';

.landscape-container {
  top: 0px;
  left: 0px;
  position: fixed;
  width:100vw;
  height:100vh;
  background-color: $pink;
  color: #000000;
  z-index: 1011;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    // width: fit-content;
    font-weight: 900;
    // position: relative;
  }
}