@import '../__styles/globals.scss';
@import '../__styles/responsive.scss';
@import '../__styles/mixins.scss';

$dope: 'dope';
@font-face {
  font-family: 'dope';
  src: url(../assets/Dopestyle.ttf) format('truetype');
}

@keyframes bloopbleep {
  0% {
    opacity: 0;
    transform: scale(1);
    transform: rotate(0deg);
  }
  50% {
    color: $purple;
    transform: rotate(0deg);
    transform: scale(1.7);
  }
  70% {
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
    transform: rotate(-10deg);
  }
}

.contact-container {
  position: relative;
  margin: 10% auto;
  padding: 0 84px;
  font-size: 2em;
  border: 0px;
  font-family: $dope;
  font-weight: 100;
  @include fade-load-mixin;

  .contact-item {
    color:black;
    margin: 10px 0 0 100px;
    font-size: 2.5em;
    cursor: pointer;
    animation: bloopbleep 700ms ease-in-out 0s 1;
    transition: all 500ms;
    &:hover {
      transform: scale(1.2) skew(-11deg) rotate(-11deg);
    }
  }
  
  .email-address {
    color:$pink;
    transform: skew(-11deg) rotate(-11deg);
    .fa-clipboard {
      margin-left: 20px;
    }
    img {
      width: 100px;
    }
    span {
      font-size: 1em;
      margin: 0;
      color:$teal;
    }
  }

  @media only screen and (max-width: $lgDevice) {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    bottom: 100px;
    position: relative;
    font-size: calc(1.8em + 1vw);
    .contact-item {
      margin: 0;
    }
  }
  
  @media only screen and (max-width: $mdDevice) {
    padding: 0;
    top: 3%;
    margin: 0;
    display: flex;
    left: 5px;
    flex-flow: column;
    font-size: 1.8em;
    justify-content: center;
    align-items: center;
  }
  
  @media only screen and (max-width: $smDevice) {
    top: 3%;
    display: flex;
    left: 5px;
    flex-flow: column;
    font-size: calc(1em + 1vw);
    justify-content: center;
    align-items: center;
  }
  
  @media only screen and (max-width: $xsDevice) {
    width: 100%;
    top: 5%;
    left: 5px;
    padding: 0;
    font-size: calc(0.8em + 1vw);
  }
}