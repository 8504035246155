

.item-container {
  max-width: 700px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  background-color: blueviolet;
  width:auto;
  height:auto;
  margin:3px;  
  padding:10px;
  color:#fff;
  border-radius: 3px;
  
  
  .item-title {
    background-color: rgba(255,255,255,0);
    font-weight: 800;
    color:#fff;
    margin: 0;
    padding: 0;
  }
  .tech-stack {
    color:#fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .item-description {
    font-size: 16px;
    background-color: rgba(255,255,255,0.5);
    color:#000;
    padding: 15px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

}