@import '../__styles/globals.scss';
@import '../__styles/responsive.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '../__styles/mixins.scss';

$dope: 'dope';
@font-face {
  font-family: 'dope';
  src: url(../assets/Dopestyle.ttf) format('truetype');
}
$bomb: 'bomb';
@font-face {
  font-family: 'bomb';
  src: url(../assets/Painterz.ttf) format('truetype');
}

.work-container {
  height: calc(100vh - 220px);
  max-width: 1250px;
  width: 70%;
  color: #fff;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @include fade-load-mixin;
  
  .slick-slider {
    height: calc(100vh - 380px);
    border:none;
    align-content: center;
    padding:20px;
    .slick-arrow {
      color:$grayish;
      background-color: $lighterpink;
      padding: 5px;
      width: 30px;
      height: 44px;
      padding-top: 7px;
      padding-left: 5px;
      border-radius: 4px;
      z-index: 1;
    }
  }

  .work-data-list-contaner {
    margin: 5%;
    margin-left: 10%;
    background-color: rgba(255,255,255,0.9);
    display: flex;
    flex-flow: column;
    justify-content: center;
    .data-item-container {
      font-size: 1em;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      
      .data-item-general {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        
      }

      a {
        text-decoration: none;
        color:$pink;
        font-size: 1.2em;
        overflow: hidden;
      }
      .url-and-github-styles {
        transition: all 500ms;
        &:hover {
          transform: scale(1.2);
          color:darken($pink, 20%);
        }
      }
      .item-name{
        font-size: 2em;
        letter-spacing: 2px;
        text-align: center;
        padding-top: 10px;
        font-family: $bomb;
        font-weight: 100;
        color:$darkGrayish;
      }  
      .item-stack{
        font-size: 1.5em;
        padding: 0 20px;
        text-align: center;
        color:$purple;
      }
      .item-description{}
      .item-url{}
      .item-github{}

    }
  }

  @media only screen and (max-width: $lgDevice) {
    max-width: $lgDevice;
    width: 80%;
    margin-top: 10px;
    width: 87%;
    height: 53vh;
  }

  @media only screen and (max-width: $mdDevice) {
    margin-top: 42px;
    width: 87%;
    height: 70vh;
    .slick-slider {
      border:none;
      align-content: center;
      .slick-arrow {
        color:black;
      }
      // .slick-dots {
      //   display: none;
      //   padding:0;
      //   padding-bottom: 5px;
      //   box-sizing: border-box;
      //   li button:before {
      //     color: $yellow;
      //     font-size: 20px;
      //   }
      // }
    }
    
    .work-data-list-contaner {
      display: flex;
      flex-flow: column;
      height: 98%;

      .data-item-container {
        font-size: 1em;

        a {
          text-decoration: none;
          color:$pink;
          font-size: 1em;
          overflow-wrap: break-word;        
          &:hover {
            color:$lighterpink;
          }
        }

        .item-name{
          font-size: 1.5em;
        }

        .item-description{}

        .item-url {
          overflow-wrap:hyphens;
        }

        .item-github{
          overflow-wrap:hyphens;
        }

        .url-and-github-styles {
          padding:0;
          // padding: 5px 0 5px 0;
        }

        .item-stack{
          font-size: 1em;
          padding: 0 5px;
          text-align: center;
          color:$purple;
        }
      }
    }
  }
  
  @media only screen and (max-width: $smDevice) {
    margin-top: 35px;
    width: 87%;
    height: 70vh;
  }
}